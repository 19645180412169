
var server = window.location.protocol + "//" + window.location.host + '/' + base_path;
var api = server+'api/';


var loginRequired = function($q, $location, $auth, $rootScope, $localStorage) {
    var deferred = $q.defer();
    if ($auth.isAuthenticated() && $localStorage.user.active) {
        deferred.resolve();
    } else {
        $rootScope.$broadcast('auth:logout-success');
    }
    return deferred.promise;
}

var adminRequired = function($q, $location, $auth, $rootScope, $localStorage) {
    var deferred = $q.defer();
    if ($auth.isAuthenticated() && $localStorage.user.roles[0].name == 'admin') {
        deferred.resolve();
    } else {
        if ($auth.isAuthenticated() ) {
            $location.path('/permission');
        } else {
            $location.path('/auth');
        }
    }
    return deferred.promise;
}

var app = angular.module('iportao', [
    'ui.router',
    'ngAnimate',
    'slugifier',
    'ngSanitize',
    'chart.js',
    'home',
    'auth',
    'config',
    'roles',
    'doorman',
    'device',
    'device_model',
    'users',
    'accounts_plan',
    'categories',
    'templates',
    'invite',
    'integrator',
    'request',
    'client',
    'duScroll',
    'permission',
    'ngCsvImport',
    'profile',
    'ngImgCrop',
    'share',
    'idf.br-filters',
    'welcome',
    'mgo-angular-wizard',
    'angular-intro',
    'underscore',
    'accesses',
    'report',
    'highcharts',
    'ui.utils.masks',
    'support',
]);


app.config([

    '$authProvider',
    '$stateProvider',
    '$urlRouterProvider',
    '$locationProvider',
    'ngToastProvider'
    , function(
        $authProvider,
        $stateProvider,
        $urlRouterProvider,
        $locationProvider,
        ngToast
    ) {

    // Wow.js
    new WOW().init();

    ngToast.configure({
      verticalPosition: 'top',
      horizontalPosition: 'center',
      maxNumber: 3
    });

    /**
     * Auth Provider
    */
    $authProvider.storageType = 'localStorage';
    $authProvider.loginUrl    = server+'login';
    $authProvider.signupUrl   = server+'signup';
    $authProvider.facebook({
        clientId: '958036947660525',
        url: base_path + 'auth/facebook'
    });

    $locationProvider.html5Mode(true);
    //$urlRouterProvider.otherwise("/");

    $stateProvider

    // Main application state
    .state('app', {
        abstract: true,
        template: '<ui-view/>'
    })

    .state('app.public', {
        abstract: true,
        templateUrl: server+"/views/public/public"
    })

    .state('app.admin', {
        abstract: true,
        resolve     : {
            loginRequired: adminRequired
        },
        templateUrl: server+"/views/public/admin",
    })

    .state('app.user', {
        abstract: true,
        resolve     : {
            loginRequired: loginRequired
        },
        templateUrl: server+"/views/public/admin",
    })

    .state('notFound', {
        url: "/404",
        templateUrl: server+'/views/errors/404',
        controller: 'list',
        ncyBreadcrumb: {
        parent: 'home',
            label: 'Página não encontrada'
        }
    });

}]);

app.run(['$state','$rootScope','$http','$localStorage','$auth',
function ($state,$rootScope,$http,$localStorage,$auth) {

    new WOW().init();

    $rootScope.$state = $state;

    var getMenu = function() {
        /**
         * Retrieve Menus
         */
        var menu_url;
        menu_url = $auth.isAuthenticated() ? server + '/views/m/menu/admin' : server + '/views/public/menu/admin.blade.php';
        $http.get(menu_url).then(function(response) {
            $rootScope.menu_admin = response.data;
        });

        menu_url = $auth.isAuthenticated() ? server + '/views/m/menu/user' : server + '/views/public/menu/user.blade.php';
        $http.get(menu_url).then(function(response) {
            $rootScope.menu_user = response.data;
        });
    };

    getMenu();

    /**
     * Listen when user is authenticated
     */
    $rootScope.$on('auth:login-success', function(e) {
        $http.get(api + 'user/profile').then(function(e) {
            $localStorage.user = e.data;
            $rootScope.user = e.data;
            if( !$rootScope.user.active ){
                $state.go('confirm_register');
            }else{
                if( $rootScope.user.roles[0].name == 'admin'){
                    $state.go('home');
                }else{
                    $state.go('permission');
                }
            }
            $rootScope.isAuthenticated = $auth.isAuthenticated();
            getMenu();
        });
    });

    /**
     * Listen when user logged out
     */
    $rootScope.$on('auth:logout-success', function(e) {
        delete $localStorage.user;
        $rootScope.isAuthenticated = $auth.isAuthenticated();
        setTimeout(() => {
            $state.go('auth');
            getMenu();
        }, 200);
    });

    $rootScope.dropdownTranslation = {
        checkAll: 'Todos',
        uncheckAll: 'Nenhum',
        enableSearch: 'Habilitar pesquisa',
        disableSearch: 'Desabilitar pesquisa',
        searchPlaceholder: 'Pesquisar...',
        buttonDefaultText: 'Selecione',
        selectionCount: 'selecionado(s)',
        dynamicButtonTextSuffix: 'selecionado(s)'
    };



}]);

// app.service('loginRequired', [ '$q', '$location', '$auth', '$rootScope', '$localStorage',
// function($q, $location, $auth, $rootScope, $localStorage){
//     var deferred = $q.defer();
//     if ($auth.isAuthenticated() && $localStorage.user.active) {
//         deferred.resolve();
//     } else {
//         $rootScope.$broadcast('auth:logout-success');
//         $location.path('/auth');
//     }
//     return deferred.promise;
// }]);

app.directive('onKeyEnter', [ '$timeout', function ($timeout) {
    return function(scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if(event.which === 13) {
                scope.$apply(function (){
                    scope.$eval(attrs.onKeyEnter,{$event: event});
                    $timeout(function(){
                        $(event.target).parent().parent().find("input").last().focus();
                    });
                });
                event.preventDefault();
            }
        });
    };
}]);
